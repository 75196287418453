import React from 'react';

class SwitchMenuMobile extends React.Component {
    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            document.querySelector('.nav-toggle').onclick = () => {
                document.querySelector('.nav-toggle').classList.toggle('active');
                document.querySelector('.header-container .menu').classList.toggle('active');
            };

            window.addEventListener('routeChange', (e) => {
                if (document.querySelector('.nav-toggle')) {
                    document.querySelector('.nav-toggle').classList.remove('active');
                    document.querySelector('.header-container .menu').classList.remove('active');
                }
            });
        }
    }

    render() {
        return (
            <a href="#" className="nav-toggle color-white">
                <span className="nav__icon"><span /><span /><span /></span>
            </a>
        );
    }
}

export default SwitchMenuMobile;
