import React from 'react';
import { NSCartProductCounter, NSContext, NSToast, getCart } from 'aqlrc';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';


class CartProductCounter extends NSCartProductCounter {
    render() {
        const { cart } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        const { routerLang } = props;
        return (
            <Link route="cart" params={{ lang: routerLang }}>
                <a className="cart__inner link">
                    <span className="cart__icon">
                        <i className="ico-bag" />
                    </span>

                    <span className="cart__content">({cart && cart.items ? cart.items.length : '0'})</span>
                </a>
            </Link>
        );
    }

    static contextType = NSContext;
}

export default withI18next(['common'])(CartProductCounter);
