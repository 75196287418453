import React from 'react';
import ModalR from 'react-responsive-modal';
import { NSContext, NSProductCard } from 'aqlrc';
import routes, { Link } from '../routes';
import { formatPrice } from '../lib/utils';
import { withI18next } from '../lib/withI18n';


class ProductCard extends NSProductCard {
    goDemo = (e) => {
        e.preventDefault();
        const { value: product } = this.props;
        if (product.description1 && product.description1.title) {
            window.open(product.description1.title, '_blank');
        }
    }

    goToContact = (e) => {
        e.preventDefault();
        window.open('https://www.aquila-cms.com/contactez-nous', '_blank');
    }

    render() {
        const { from, mode, t } = this.props;
        const { openModal, product } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        const {
            lang, query, routerLang, taxDisplay, urlLang
        } = props;

        if (!product.canonical) {
            return <p>Error : no canonical</p>
        }
        
        const product_route = routes.findAndGetUrls(product.canonical);

        let imgDefault = '/medias/no-image.jpg';
        let imgAlt = '';
        if (product && product.images && product.images.length) {
            const foundImg = product.images.find((img) => img.default);
            if (foundImg) {
                imgDefault = foundImg._id !== 'undefined' ? `/images/products/220x${mode !== 'themes' ? '137' : '273'}-100/${foundImg._id}/${product.slug[lang]}${foundImg.extension}` : imgDefault;
                imgAlt = foundImg.alt ? foundImg.alt : '';
            } else {
                imgDefault = product.images[0]._id !== 'undefined' ? `/images/products/220x${mode !== 'themes' ? '137' : '273'}-100/${product.images[0]._id}/${product.slug[lang]}${product.images[0].extension}` : imgDefault;
                imgAlt = product.images[0].alt ? product.images[0].alt : '';
            }
        }

        // Pictos
        const pictos = [];
        if (product.pictos) {
            product.pictos.forEach((picto) => {
                if (pictos.find((p) => p.location === picto.location) !== undefined) {
                    pictos.find((p) => p.location === picto.location).pictos.push(picto);
                } else {
                    const cardinals = picto.location.split('_');
                    const style = { top: 0, left: 0 };
                    if (cardinals.includes('RIGHT')) {
                        style.left = 'inherit';
                        style.right = 0;
                    }
                    if (cardinals.includes('BOTTOM')) {
                        style.top = 'inherit';
                        style.bottom = 0;
                    }
                    if (cardinals.includes('CENTER')) {
                        style.left = '50%';
                        style.transform = 'translate(-50%, 0)';
                    }
                    if (cardinals.includes('MIDDLE')) {
                        style.top = '50%';
                        style.transform = 'translate(0, -50%)';
                    }
                    pictos.push({ location: picto.location, style, pictos: [picto] });
                }
            });
        }

        const price = product.price.et.special >= 0 ? product.price.et.special : product.price.et.normal;

        return (
            <>
                <div className="card card--promo">
                    <div className="card__inner">
                        <div className="card__image poster" style={{ backgroundImage: `url(${imgDefault})`, height: mode === 'themes' ? '220px' : null }}>
                            {
                                pictos ? pictos.map((picto) => (
                                    <div className="product_picto" style={picto.style} key={picto.location + Math.random()}>
                                        {
                                            picto.pictos && picto.pictos.map((p) => <img src={`/images/picto/32x32-70/${p.pictoId}/${p.image}`} alt={p.title} title={p.title} key={p._id} />)
                                        }
                                    </div>
                                )) : ''
                            }
                        </div>

                        <div className={`article${mode === 'themes' ? ' themes' : ''}`}>
                            <h3>{product.name}</h3>

                            {mode !== 'themes' && <p dangerouslySetInnerHTML={{ __html: product.description2 ? product.description2.title : '' }} />}
                        </div>{/* <!-- /.article --> */}

                        <Link href={product_route.urls.href} as={product_route.urls.as}>
                            <a>
                                <div className="card__overlay">
                                    <div className="card__add">
                                        {
                                            (mode === 'modules' || mode === 'themes' || price > 0) && (
                                                mode !== 'themes' ? (
                                                    <button type="button" className="btn btn--orange" onClick={product.type === 'virtual' && product.downloadLink && price === 0 ? (e) => this.downloadVirtual(e, product.downloadLink) : this.addToCart}>
                                                        <i className="ico-bag" />
                                                    </button>
                                                ) : (
                                                    <button button type="button" className="btn btn--orange" onClick={this.goDemo} style={{ padding: '8px 6px 6px' }}>
                                                        {t('product-card:demo')}
                                                    </button>
                                                )
                                            )
                                        }
                                        {
                                            product.type === 'virtual' && price === 0 ? (
                                                mode === 'modules' || mode === 'themes' ? (
                                                    <button type="button" className="btn" onClick={this.downloadVirtual}>{t('product-card:download')}</button>
                                                ) :(
                                                    <button type="button" className="btn" onClick={this.goToContact}>{t('product-card:contact')}</button>
                                                )
                                            ) : (
                                                <button type="button" className="btn" onClick={this.addToCart}>{t('product-card:ajoutPanier')}</button>
                                            )
                                        }
                                    </div>{/* <!-- /.card__add --> */}
                                </div>{/* <!-- /.card__overlay --> */}
                            </a>
                        </Link>
                    </div>{/* <!-- /.card__inner --> */}

                    <div className="card__info">
                        {
                            // <div className="rating">
                            //     {
                            //         product.reviews.reviews_nb > 0 && (
                            //             <>
                            //                 <div className="stars">
                            //                     {
                            //                         [...Array(5)].map((el, idx) => (
                            //                             <div key={idx} className="star" style={{ width: '13px' }}>
                            //                                 <i className="ico-star" style={idx < Math.round(product.reviews.average) ? null : { filter: 'grayscale(100%)' }} />
                            //                             </div>/* <!-- /.star --> */
                            //                         ))
                            //                     }
                            //                 </div>{/* <!-- /.stars --> */}

                            //                 <div className="rating__content">
                            //                     ({product.reviews.reviews_nb})
                            //                 </div>{/* <!-- /.rating__content --> */}
                            //             </>
                            //         )
                            //     }

                            //     <a href="#" className="rating__link" />
                            // </div>{/* <!-- /.rating --> */}
                        }

                        <div className="price" hidden={price === 0 && mode !== 'modules' && mode !== 'themes'}>
                            {
                                product.price.et.special >= 0 && (<del>{formatPrice(product.price.et.normal)}</del>)
                            }

                            {price > 0 ? <>{formatPrice(price)}</> : t('product-card:free')}
                        </div>{/* <!-- /.price --> */}
                    </div>{/* <!-- /.card__info --> */}
                </div>{/* <!-- /.card --> */}
                <ModalR
                    animationDuration={0} classNames={{ modal: 'popup__container', overlay: 'popup active' }}
                    open={openModal} onClose={this.onCloseModal} center
                >
                    <h3 className="popup__title">{t('product:productAdded')} :</h3>
                    <div className="popup__body">
                        <div className="product-simple">
                            <figure className="product__image" style={{ textAlign: 'center' }}>
                                <img src={imgDefault} alt={imgAlt} />
                            </figure>

                            <h4 className="product__title">{this.state.selectedQty} x {product.name}</h4>

                            <div className="product__actions" style={{ marginTop: '20px', textAlign: 'center' }}>
                                <button type="button" className="btn btn--color color-purple" onClick={this.onCloseModal}>
                                    <span className="btn__content">{t('product:continueShopping')}</span>
                                </button>

                                <Link route="cart" params={{ lang: routerLang }}>
                                    <a className="btn btn--color color-purple" style={{ marginLeft: '10px' }}>
                                        <span className="btn__content">{t('product:viewCart')}</span>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </ModalR>
            </>
        );
    }

    static contextType = NSContext;

    static defaultProps = {
        includeCss : true,
        mode       : 'modules',
        product    : {
            _id    : '',
            images : [],
            name   : 'Undefined',
            price  : {
                et  : { normal: -1 },
                ati : { normal: -1 }
            },
            description1 : { title: '', text: '' },
            description2 : { title: '', text: '' },
            reviews      : {}
        },
        gridDisplay : true
    };
}

export default withI18next(['product-card', 'product'])(ProductCard);
