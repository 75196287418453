import React from 'react';
import { NSContext, NSLanguageChange } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

class LanguageChange extends NSLanguageChange {
    render() {
        const { props } = this.context;
        if (!props) { return null; }
        const { lang, langs } = props;
        return (
            <select value={lang} onChange={(e) => this.switchLang(e.target.value)}>
                {
                    langs.filter((l) => l.status === 'visible').map((l) => (
                        <option key={l.code} value={l.code}>
                            {l.code.toUpperCase()}
                        </option>
                    ))
                }
            </select>
        );
    }

    static contextType = NSContext;
}

export default withI18next([])(LanguageChange);
