import React from 'react';
import crypto from 'crypto';
import { NSContext, NSProductCardList } from 'aqlrc';
import ProductCard from './ProductCard';
import { Router } from '../routes';
import { withI18next } from '../lib/withI18n';

class ProductCardList extends NSProductCardList {
    render() {
        const {
            itemsperslides, mode, type, value
        } = this.props;
        let { products } = this.props;
        const { currentIndex } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        if (!products.length) {
            const hash = crypto.createHash('md5').update(`${type}_${value}`).digest('hex');
            products = this.context.props[`nsProductCardList_${hash}`] ? this.context.props[`nsProductCardList_${hash}`] : [];
        }
        return (
            <div className="cards" hidden={products.length < 1}>
                <div className="cards__inner">
                    {
                        [...Array(Number(itemsperslides))].map((el, index) => {
                            if (products[itemsperslides * (currentIndex - 1) + index] !== undefined) {
                                const product = products[itemsperslides * (currentIndex - 1) + index];
                                return <ProductCard key={itemsperslides * (currentIndex - 1) + index} mode={mode} type="data" value={product} includeCss={index === 0} gNext={{ Router }} />;
                            }
                        })
                    }
                </div>
                {
                    itemsperslides >= products.length
                        ? ''
                        : (
                            <div className="slider__arrows">
                                <button onClick={() => this.slide(products.length, true)} type="button" className="slider__arrow slider__arrow--prev">
                                    <img src="/static/images/sprite/arrow.png" style={{ transform: 'rotate(180deg)' }} width="30" height="30" alt="arrow" />
                                </button>
                                <button onClick={() => this.slide(products.length)} type="button" className="slider__arrow slider__arrow--next">
                                    <img src="/static/images/sprite/arrow.png" width="30" height="30" alt="arrow" />
                                </button>
                            </div>
                        )
                }
            </div>
        );
    }

    static contextType = NSContext;

    static defaultProps = {
        products       : [],
        itemsperslides : 4,
        mode           : 'modules'
    };
}

export default withI18next()(ProductCardList);
