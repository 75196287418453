import React from 'react';
import {
    _NSconfig
} from 'aqlrc'; // Import depuis AQLRC de la config par défaut
import BlockSlider from './BlockSlider';
import ButtonLoginAccount from './ButtonLoginAccount';
import CartProductCounter from './CartProductCounter';
import LanguageChange from './LanguageChange';
import PricingTables from './PricingTables';
import ProductCard from './ProductCard';
import ProductCardList from './ProductCardList';
import SwitchMenuMobile from './SwitchMenuMobile';

// On surcharge la config par défaut de AQLRC si besoin
// A noter que <Link> et <CMS> sont déjà gérés directement dans le composant CMS, il faut utiliser respectivement "ns-link" et "ns-cms"
// A garder dans l'ordre alphabétique en fonction du nom du composant SVP
export default {
    ..._NSconfig,
    'ns-block-slider'      : <BlockSlider />,
    'ns-login'             : <ButtonLoginAccount />,
    'ns-cart-count'        : <CartProductCounter />,
    'ns-lang'              : <LanguageChange />,
    'ns-pricing-tables'    : <PricingTables />,
    'ns-product-card'      : <ProductCard />,
    'ns-product-card-list' : <ProductCardList />,
    'ns-switch-menu'       : <SwitchMenuMobile />
};
